import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'form'];

  toggle() {
    if (this.contentTarget.classList.contains('hidden')) {
      this.contentTarget.classList.remove('hidden');
      this.formTarget.classList.add('hidden');
    } else {
      this.contentTarget.classList.add('hidden');
      this.formTarget.classList.remove('hidden');
    }
  }
}
